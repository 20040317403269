@import 'primeng/resources/primeng.css';
@import '@pv-frontend/pv-shared-components/resources/styles/theme.css';
@import '@pv-frontend/pv-shared-components/resources/styles/primeflex.css';
@import '@pv-frontend/pv-shared-components/resources/styles/custom-theme.scss';
@import './assets/variables.css';
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');

html,
body {
  margin: 0;
  font-size: 16px;
  line-height: 1.5;
  // background-color: var(--background-light-base);
  &,
  * {
    font-family: 'Lato' !important;
  }
}

:root {
  --light-primary: #cb5387;
}

:root {
  --light-blue: #5978de;
  --dark-grey: #192839;
  --soft-black: #00000017;
  --charcoal-blue: #2b4460;
  --navy-blue: #1f3651;
  --neutral-black-1: #f3f3f3;
  --grey-4: #6d6e71;
  --light-grey: #d1d3d4;
  --claimed-green-bg: #d7e8e1;
  --claimed-green-text: #09965b;
  --claimed-green-tick: #48d08c;
}

.hide-scrollbar {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}
.content-width {
  max-width: 50rem;
  margin: 0 auto;
}
.card-border {
  border: 1px solid #d1d3d4;
  border-radius: 8px;
}

.logout-text,
.logout-text:hover {
  border: 1px solid var(--primary-color) !important;
  background-color: var(--primary-color) !important;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.14);
  color: var(--surface-a);
  margin-top: 10px;
}
.p-button {
  padding: 0.5rem;
}
.exit-icon {
  fill: var(--surface-a);
}
.p-sidebar .p-sidebar-header {
  padding: 0;
}

.p-sidebar .p-sidebar-footer {
  padding-top: 0;
}

.auth-header {
  background-color: var(--primary-color);
  height: 64px;
}
.auth-header img {
  width: 153px;
}
pv-authentication .p-dropdown-panel {
  right: 10px;
}
pv-authentication .p-sidebar {
  max-width: 448px !important;
  min-height: 350px;
}
@media (max-width: 965px) {
  pv-authentication .p-sidebar {
    max-width: 100%;
  }
}
@media (max-width: 768px) {
  pv-authentication {
    --modalHeight: 450px !important;
  }
  .p-dropdown-panel {
    height: 200px !important;
    width: 100% !important;
  }
}

.box-shadow-primary {
  box-shadow: inset 0 0 74px 24px rgba(0, 0, 0, 0.09);
  -moz-box-shadow: inset 0 0 74px 24px rgba(0, 0, 0, 0.09);
}

.p-sidebar-content::-webkit-scrollbar {
  display: none;
}

.p-dropdown-items-wrapper::-webkit-scrollbar {
  display: none;
}

.p-dropdown-panel::-webkit-scrollbar {
  display: none;
}
